/* Missionvision.css */
.container-square {
  width: 90%;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  z-index: 2;
  gap: 300px;
}
.container-square > * {
  margin-right: 10px;
}

.container-square > *:last-child {
  margin-right: 0;
}


.container-square-background {
  background: linear-gradient(110deg, rgba(196, 232, 153, 1) 0%, rgb(255, 255, 255) 51%, rgb(255, 255, 255) 51%, rgb(255, 255, 255) 100%);

}

.info-container {
  width: 40%;
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.highlighted-text-1 {
  font-weight:1000;
}
.highlighted-text-2 {
  color: #c4e899 !important;
}

.info-title {
  font-size: 5rem;
  font-weight: bolder;
  margin-bottom: 5%;
  color: rgb(0, 0, 0);
  text-align: left;
}

.location-title {
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
  text-align: justify;
}

@media (max-width: 1024px) {
  .container-square {
    flex-direction: column;
    height: auto;
  }

  .info-container {
    width: 100%;
    margin: 0;
  }

}
