.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}


.container_nav {
  max-height: 9em;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-links {
  display: flex;
  color: #fff;
}

.nav-links li {
  margin-left: 10px;
  list-style: none;
}

.nav_home,
.nav_semblance,
.nav_mission,
.nav_servicecards,
.nav_carousel {
  text-decoration: none;
  color: #000;
  font-weight: normal;
  transition: color 0.3s ease-in-out;
}

.nav_home:hover,
.nav_semblance:hover,
.nav_mission:hover,
.nav_servicecards:hover,
.nav_carousel:hover {
  color: #555;
}

.nav_home.active,
.nav_carousel.active,
.nav_mission.active,
.nav_servicecards.active,
.nav_carousel.active {
  color: #69ac8d;
  font-weight: bold;
}

.nav-links a {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}

.navbar .menu-icons {
  background: transparent;
  border: none;
  display: none;
}

/* Media Queries */
@media screen and (max-width: 756px) {
  .nav-links {
    display: none;
    border: 2px solid #fff;
    z-index: 111;
  }

  .navbar .menu-icons {
    display: block;
  }

  .navbar #nav-links-mobile,
  .navbar #nav-links-mobile-hide {
    display: block;
    position: absolute;
    list-style: none;
    background: #69ac8d;
    opacity: 98%;
    left: 0;
    top: 80px;
    width: 100%;
    transition: all 0.3s;
    border: none;
    padding-bottom: 5px;
  }

  .navbar #nav-links-mobile {
    animation: slide-in 0.5s ease-in-out;
  }

  .navbar #nav-links-mobile-hide {
    left: -100%;
    animation: slide-out 0.5s ease-in-out;
  }
  @keyframes slide-out {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .navbar #nav-links-mobile li,
  .navbar #nav-links-mobile-hide li {
    text-align: center;
    padding: 10px 0;
    margin: 0 30px;
  }

  .navbar #nav-links-mobile li:last-child {
    border-bottom: none;
  }

  .navbar #nav-links-mobile li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  .navbar #nav-links-mobile a:hover {
    color: orangered;
  }
}