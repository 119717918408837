@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

.privacy-content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.privacy-content {
    background-color: whitesmoke;
    color: black;
    width: 80%;
    font-family: 'sans-seriff';
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    text-align-last: left;
    padding: 10px;
}

.h1-privacy{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: underline;
}