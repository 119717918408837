.service {
  display: flex;
  flex-direction: column;
  border: none;
  width: 423px;
  height: 590px;
  margin: 2rem;
}

.icon-size{
  width: 23%;
}

.icon-size-wide {
  height: 95.94px;
  padding: 1.5rem;
}

.servicesList {
  list-style-type: none;
  margin: 1%;
  margin-left: 4%;
  margin-right: 4%;
  border-left: 10px solid #c4e899;
  padding: 2%;
  padding-left: 1%;
  font-size: x-large;
  font-weight: bolder;
  max-height: 200px;
  height: 150px;
  transition: background-color 0.3s, border-left 0.3s;
}
.servicesList:hover {
  background-color: #c4e899 !important;
  border-left: 10px solid #dfdfdf;
}

.card-text{
  font-size: large;
}
.card-text.bolder{
  font-weight: bold;
}

.container {
  position: relative;
}

.card-button {
  background-color: #758a5b;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: background-color 0.3s, color 0.3s;
  font-size: small;
  width: 12vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.card-button:hover {
  background-color: #50534c;
}

@media screen and (max-width: 800px) {
  .service {
    height: auto;
    max-height: none !important;
    min-height: 570px !important;
    width: 358px;
    padding: 20px !important;
  }

  .title-desc-services {
    width: 100%;
    font-size: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title-desc-services h4 {
    font-size: 2.5rem;
  }

  .u-text-small {
    font-size: 2.5vw;
  }

  .card-text{
    font-size: small;
  }

  .card-button {
    font-size: small;
    padding: 5px 10px;
  }
  
}
@media screen and (max-width: 564px) {
  .card-text{
    font-size: 2vw;
  }
  .card-button {
    font-size: 2vw;
    padding: 1px 5px;
  }
}