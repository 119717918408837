.container-diamond {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.img-background {
  width: 420px;
  height: 680px;
  left: 0px;
  top: 0px;
  background: url("../../assets/profile_v2.jpg"), #333333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
.inner-container {
  color: #f3baba;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
}

.card-text {
  font-family: 'sans-seriff';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 28px;
  text-align: center;
  color: black !important;
  text-align: justify;
}

.h-titleS {
  font-family: 'sans-seriff';
  font-style: normal;
  font-weight: bold;
  font-size: 15px !important;
  text-transform: uppercase;
  color: #c4e899;
}
.h-titleSub {
  font-family: 'sans-seriff';
  font-style: normal;
  font-weight: bold;
  font-size: 27px !important;
  color: #000000;
  margin-bottom: 30px;
}
.highlighted-text {
  color: #c4e899 !important;
}

section#download {
  background: linear-gradient(
    90deg,
    rgba(45, 165, 255, 1) 45%,
    rgba(1, 223, 245, 1) 100%
  );
  text-align: center;
  color: #fff;
}

.download h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  position: relative;
  padding-bottom: 1rem;
}

.download h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  transform: translateX(-50%);
}
.download-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.slide:hover,
.slide:focus {
  box-shadow: inset 6.5em 0 0 0 var(--hover);
}

@media screen and (max-width: 1000px) {
  .h-title {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 600px) {
  .container-diamond {
    flex-direction: column;
    text-align: center;   
    height: 1073px;
  }
  .inner-container {
    max-width: 350px;
  }

  .img-background {
    width: 357px;
    height: 583px !important;
    left: 0px;
    top: 0px;
    border-radius: 24px;
  }
}

/* boton */

.slide {
  background-color: #c4e899;
  max-width: 480px;
  margin-top: 10%;
  padding: 3%;
  border-radius: 24px;
  width: 50vw;
  border: none;
  color: #fff;
  font-size: 1.5vw;
  transition: background-color 0.3s, transform 0.3s;
}

.slide:hover {
  background-color: #224934;
  transform: scale(1.05);
}
