.header-bg {
    width: 100%;
    height: 50%;
    min-height: 200vh;
    background: #ffffff;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
  
  }
  