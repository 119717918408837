body, html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

#footer {
  width: 100%;
  background: #69ac8d;
  color: #fff;
}

.container_footer {
  margin: 0 auto;
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
}


section#footer {
  background: #69ac8d;
  color: #fff;
}

.hidden {
  display: none;
}

.footer h4 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

@media (max-width: 800px) {
  .footer-box a {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  section#footer {
    width: 400px;
  }

}
