.spacer{
    height: 5vh;
}
.img-background-about {
    width: 420px;
    height: 680px;
    left: 0px;
    top: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

  @media screen and (max-width: 600px) {
    .img-background-about {
      width: 357px;
      height: 583px !important;
      left: 0px;
      top: 0px;
      border-radius: 24px;
    }
  }