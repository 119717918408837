.container-proposition {
    justify-content: space-evenly !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.container-section-home-title-section {
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: 'sans-seriff';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 1.4;
    color: rgb(0, 0, 0);
    position: relative;
    padding-bottom: 0.5rem;
    margin-left: 5%;
}

.underlined-text{
    text-decoration: underline;
    text-decoration-color: #64ac8a;
}

.service {
    min-height: 300px;
    max-height: 700px;
    border: 1px solid gray;
    height: 350px !important;
}

.section-background2 {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
    background-clip:padding-box;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

.h-title {
    font-family: 'Sans-Serif';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #000000;
}

@media screen and (max-width: 800px){
    .service {
        min-height: 300px !important;
        height: 370px !important;
    }
}
.container-proposition {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.section-background {
    background-color: #ffffff;
    color: white;
    
}

.h-title {
    font-family: 'sans-seriff';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 34px;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 2.5rem;
}

.u-text{
    display: flex;
    justify-content: center;
}

.u-title-proposition {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 8rem;
}

