@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

#header {
  position: relative;
  padding-top: 0%;
  padding-bottom: 0%;
}
a {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
.header {
  max-width: 100%;
  max-height: 100%;
  height: 45em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; 
  padding: 0px 100px;
  background-image: url("../../assets/backgroundBlur.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header h1 {
  font-family: 'sans-serif';
  font-style: normal;
  font-weight: bolder;
  
  font-size: 7rem;
  line-height: 1.4;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  padding-bottom: 2.5rem;
  padding-top: 10rem;
}

.header h1 span {
  display: block;
}

.long {
  white-space: normal;
}

.header-left p {
  margin: 0.5rem 0;
  width: 100%;
  height: 72px;
  font-family: 'sans-serif';
  font-style: normal;
  font-weight: 200;
  font-size: 3rem;
  line-height: normal;
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.header-right img {
  width: 300%;
  object-position: -19ch -10em;
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .long {
    white-space:normal;
  }
  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
    width: 400px
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
  
}
