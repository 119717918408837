@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
.card-Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0px;
    margin: 3%;
    width: 100%;
    height: 390px;
    background: #876445;
    border-radius: 30px;
}

.card-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3dvh;
    padding-left: 2%;
    margin-top: 40px;
    margin-right: 20px;
    width: 45%;
    height: 75%;
    background-image: linear-gradient(to right, #876445, #f1ddc9);
    border-radius: 30px;
}

.card-info-title {
    font-family: 'sans-seriff';
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 34px;
    text-transform: uppercase;
    color: #000000;
    text-align: left;
}

.card-location-title {
    font-family: 'sans-seriff';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
    color: #000000;
    text-align: left;
}

.icon {
    font-size: 12px;
    margin-right: 5px;
}


@media (min-width:200px) and (max-width: 800px) {
    .card-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 70%;
        padding-bottom: 20px;
    }
    .card-info-container {
        width: 90%;
        
    }
}


@media (max-width: 400px) {
    .card-Container {
        margin-right: 0px;
    }
    .card-info-title {
        font-size: medium;
    }
    .card-location-title{
        font-size: large;
    }
}
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .card-location-title {
        font-size: 20px;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .card-location-title {
        font-size: 24px;
    }
  }
  @media only screen and (min-width: 1400px) {
    .card-location-title {
        font-size: 28px;
    }
}