section#subscribe {
  text-align: center;
  color: #fff;
  background-size: cover;
  background-attachment: fixed;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
}

.social-icon {
  margin: 0px !important;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2rem;
  color: #876445;
  transition: all 0.3s;
}

.social-icon:hover {
  background: var(--light-blue);
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}
